import { Fragment, useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowLineRight } from '@phosphor-icons/react'

import { ListStatus } from '@/main/features/List/components/ListStatus/ListStatus'
import { ListTitle } from '@/main/features/List/components/ListTitle/ListTitle'
import { ListMenu } from '@/main/features/List/components/ListMenu/ListMenu'
import { ListDrawerFooter } from '@/main/features/List/components/ListDrawerFooter/ListDrawerFooter'

import { Button } from '@/main/components/Button/Button'

interface DrawerProps {
  children: React.ReactNode
}

export function Drawer({ children }: DrawerProps) {
  const location = useLocation()
  const navigate = useNavigate()

  const isOpen = location.pathname.indexOf('cart') > -1

  const onClose = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Esc' && e.key !== 'Escape') return
      if (!isOpen) return
      if (e?.target && e.target instanceof Element && e.target.id === 'close-modal') return

      onClose()
    },
    [onClose, isOpen]
  )

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!isOpen) return

      if ((e.target as HTMLElement)?.id === 'acmebk-cart-drawer-overlay') {
        onClose()
      }
    },
    [onClose, isOpen]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEscape)
    return () => window.removeEventListener('keydown', handleEscape)
  }, [handleEscape])

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" unmount={false} onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="twc-ease-in-out twc-duration-500 twc-transform-gpu"
          enterFrom="twc-opacity-0"
          enterTo="twc-opacity-50"
          leave="twc-ease-in-out twc-duration-500 twc-transform-gpu"
          leaveFrom="twc-opacity-50"
          leaveTo="twc-opacity-0"
        >
          {/* Overlay */}
          <div
            id="acmebk-cart-drawer-overlay"
            className="twc-fixed twc-inset-0 twc-bg-gray-500 dark:twc-bg-gray-900 twc-bg-opacity-50 twc-z-[60]"
          />
        </Transition.Child>

        <div className="twc-fixed twc-inset-0 twc-overflow-hidden twc-z-[80] twc-pointer-events-none">
          <div className="twc-absolute twc-inset-0 twc-overflow-hidden">
            <div
              className="twc-pointer-events-none twc-fixed twc-flex twc-inset-y-0 twc-right-0 twc-max-w-full pl-10"
              id="acmebk-cart-drawer"
            >
              <Transition.Child
                as={Fragment}
                enter="twc-transform twc-transform-gpu twc-transition twc-ease-out-quart twc-duration-300 sm:twc-duration-600"
                enterFrom="twc-translate-x-full"
                enterTo="twc-translate-x-0"
                leave="twc-transform twc-transform-gpu twc-transition twc-ease-out-quart twc-duration-300 sm:twc-duration-600"
                leaveFrom="twc-translate-x-0"
                leaveTo="twc-translate-x-full"
              >
                <Dialog.Panel className="twc-pointer-events-auto twc-relative twc-w-screen twc-max-w-lg">
                  <div className="twc-flex twc-h-full twc-flex-col twc-bg-slate-50 dark:twc-bg-zinc-900 twc-pb-6 twc-shadow-xl dark:twc-shadow-slate-600">
                    <ListStatus />

                    <div className="twc-flex twc-z-20 twc-items-center twc-justify-between dark:twc-border-b-slate-700 twc-py-3 sm:twc-py-5 twc-px-5 twc-shadow dark:twc-border-b dark:twc-border-zinc-600">
                      <Dialog.Title
                        as="div"
                        className="twc-relative twc-order-1 twc-h-full twc-basis-0 twc-grow-[999] dark:twc-text-gray-100"
                      >
                        <ListTitle />
                      </Dialog.Title>

                      <Transition.Child
                        as={Fragment}
                        enter="twc-ease-out-quart twc-duration-300 twc-transform-gpu"
                        enterFrom="twc-opacity-0"
                        enterTo="twc-opacity-100"
                        leave="twc-ease-out-quart twc-duration-300 twc-transform-gpu"
                        leaveFrom="twc-opacity-100"
                        leaveTo="twc-opacity-0"
                      >
                        <div className="twc-order-last twc-flex twc-items-center twc-justify-end twc-grow-0 twc-basis-24">
                          <button
                            type="button"
                            className="twc-rounded-md twc-text-gray-700 hover:twc-text-orange-500 focus:twc-outline-none focus:twc-ring-2 focus:twc-ring-white motion-safe:twc-transition-colors dark:twc-text-gray-50 dark:hover:twc-text-orange-400"
                            onClick={onClose}
                          >
                            <span className="twc-sr-only">Close cart</span>
                            <ArrowLineRight weight="bold" size={24} aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>

                      <div className="twc-grow-0 twc-basis-24">
                        <ListMenu />
                      </div>
                    </div>

                    {/* Body */}
                    <div className="twc-relative twc-flex-1 twc-px-5 twc-overflow-auto twc-bg-white dark:twc-bg-black">
                      {children}
                    </div>

                    {/* Footer */}
                    <div className="twc-flex twc-flex-col twc-items-center twc-gap-2 xs:twc-gap-3 sm:twc-gap-5 twc-pt-5 twc-px-2 sm:twc-px-5 twc-border-t dark:twc-border-t-zinc-600">
                      <ListDrawerFooter />

                      <div className="twc-mt-2">
                        <Button variant="link" onClick={onClose} size="small">
                          <span>Continue Browsing (Close Cart)</span> <ArrowLineRight size={18} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
