import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { isValidDateStr } from '@/main/app/utils/dates'
import { Tooltip } from '@/main/components/Tooltip/Tooltip'
import { selectItemsByListId } from '@/main/features/Item/itemSlice'
import { Circle, CircleDashed, CircleHalf } from '@phosphor-icons/react'
import { useParams } from 'react-router-dom'
import { useListHistory } from '../../hooks/useListHistory'
import { selectListById } from '../../listSlice'

function formatShortDate(date?: Date | number) {
  return new Intl.DateTimeFormat('default', {
    month: 'numeric',
    day: 'numeric',
  }).format(date)
}

function formatShortDateRange(starts: string | undefined, ends: string | undefined) {
  if (!starts || !ends) return null

  const startDate = isValidDateStr(starts) && new Date(starts)
  const endDate = isValidDateStr(ends) && new Date(ends)

  if (!startDate || !endDate) return null

  return `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`
}

const statusUi = {
  initial: (isEmpty?: boolean) => ({
    text: 'New',
    bg: 'twc-bg-blue-200 dark:twc-bg-blue-900/75',
    label: isEmpty
      ? 'Your cart is empty'
      : 'Your cart is new and not yet submitted. Send it to ACME for a quote.',
    icon: (
      <CircleDashed
        weight="bold"
        size={18}
        className="twc-text-indigo-700 dark:twc-text-indigo-200"
      />
    ),
  }),
  sent: (lastSent: string) => ({
    text: 'Sent',
    bg: 'twc-bg-emerald-100 dark:twc-bg-emerald-900',
    label: `Your cart was submitted ${lastSent}. No changes to re-submit.`,
    icon: (
      <Circle
        weight="duotone"
        size={18}
        className="twc-text-emerald-700 dark:twc-text-emerald-300"
      />
    ),
  }),
  modified: (lastSent: string, changes: number | string) => ({
    text: `${changes} change(s)`,
    bg: 'twc-bg-orange-200 dark:twc-bg-amber-900',
    label: `Your cart has ${changes} change(s) since it was sent ${lastSent}`,
    icon: (
      <CircleHalf weight="bold" size={18} className="twc-text-brand dark:twc-text-orange-300" />
    ),
  }),
}

const resolveStatus = (
  time: false | string | undefined,
  current: number,
  changes: number | false | undefined
) => {
  if (changes) return statusUi.modified(`${time}`, changes)
  if (time) return statusUi.sent(`${time}`)
  if (!current) return statusUi.initial(true)

  return statusUi.initial()
}

export function ListStatus() {
  const { id } = useParams()

  const list = useAppSelector((state) => selectListById(state, id ?? ''))
  const items = useAppSelector((state) => selectItemsByListId(state, list?.id))

  const listHistory = useListHistory(list, items)

  const lastSent = listHistory && listHistory.lastSent
  const changes = listHistory && listHistory.delta

  const dates = formatShortDateRange(
    list?.form?.dates?.values.startDate,
    list?.form?.dates?.values.endDate
  )

  const status = resolveStatus(lastSent, items.length, changes)

  return (
    <div
      className={`${status.bg} twc-z-50 twc-text-sm twc-py-1 twc-px-2 twc-flex twc-justify-between twc-gap-1 twc-items-center dark:twc-text-zinc-50`}
    >
      <div className="twc-inline-flex twc-items-center">
        <Tooltip icon={status.icon} title={status.text}>
          {status.label}
        </Tooltip>
        <span>{status.text}</span>
      </div>
      {dates && list?.form?.billing?.values.reference && (
        <span>
          {dates}
          {': '}
          {list.form.billing.values.reference}
        </span>
      )}
    </div>
  )
}
