export const ItemCardSkeleton = () => (
  <div className="twc-flex twc-gap-4 twc-animate-pulse">
    <div className="twc-grow twc-basis-28 twc-aspect-4/5 twc-bg-gray-200" />

    <div className="twc-item-data twc-grid twc-grid-cols-[1fr_min-content] twc-grid-rows-[min-content_1fr_min-content] twc-gap-0.5 twc-grow-[999] twc-basis-0">
      <div className="twc-item-title twc-grow-0 twc-w-5/6 twc-h-7 twc-bg-gray-200 twc-rounded" />

      <div className="twc-item-rate twc-w-14 twc-h-7 twc-bg-gray-200 twc-rounded" />

      <div className="twc-item-meta twc-basis-full twc-leading-normal twc-py-1">
        <div className="twc-bg-gray-200 twc-w-1/3 twc-h-5 twc-rounded twc-mb-1" />
        <div className="twc-bg-gray-200 twc-w-2/3 twc-h-5 twc-rounded" />
      </div>

      <div className="twc-item-action twc-flex twc-justify-between twc-mt-auto twc-items-end">
        <div className="twc-bg-gray-200 twc-w-16 twc-h-6 twc-rounded" />
        <div className="twc-bg-gray-200 twc-w-16 twc-h-9 twc-rounded" />
      </div>
    </div>
  </div>
)
