// // eslint-disable-next-line import/no-unresolved
// import 'vite/modulepreload-polyfill'

import { createRoot } from 'react-dom/client'
import { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// import { initSentry } from './app/services/sentry'

import { store } from '@/main/app/store'
import { queryClient } from '@/main/app/services/queryClient'

import './styles/app.css'

import App from './App'

// initSentry()

const persistor = persistStore(store)

const container = document.getElementById('acmebk-cart')

if (container) {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={true} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </StrictMode>
  )
}
