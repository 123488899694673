import { lazy, Suspense } from 'react'

import type { ItemId } from '@/main/features/Item/itemSlice'

import { ItemBoundary } from '../ItemBoundary/ItemBoundary'
import { ItemCardSkeleton } from './ItemCardSkeleton'

const ItemCard = lazy(() => import('./ItemCard'))

export function ItemCards({ ids }: { ids: ItemId[] }) {
  return (
    <ul className="twc-divide-y twc-divide-slate-300 dark:twc-divide-slate-700">
      {ids.map((id) => (
        <li key={id} className="twc-py-5 twc-flex-stretch twc-w-full">
          <ItemBoundary id={id}>
            <Suspense fallback={<ItemCardSkeleton />}>
              <ItemCard id={id} />
            </Suspense>
          </ItemBoundary>
        </li>
      ))}
    </ul>
  )
}
