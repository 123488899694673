import { MemoryRouter, Routes, Route } from 'react-router-dom'

// import * as Sentry from '@sentry/react'

import { AppContext } from '@/main/app/context'
import { appSettings } from '@/main/app/global'

import { Layout } from './pages/Layout'
import { Project } from './pages/Project'
import { Projects } from './pages/Projects'

// const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
  return (
    <AppContext.Provider value={appSettings}>
      <MemoryRouter>
        {/* <SentryRoutes> */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="cart">
              <Route index element={<Projects />} />
              <Route path=":id" element={<Project />} />
            </Route>
          </Route>
        </Routes>
        {/* </SentryRoutes> */}
      </MemoryRouter>
    </AppContext.Provider>
  )
}

export default App
