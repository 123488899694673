import { Outlet } from 'react-router-dom'

import { AppButton } from '@/main/components/AppButton/AppButton'
import { Drawer } from '@/main/components/Drawer/Drawer'
import { Events } from '@/main/components/Events/Events'
import { ListSnackbar } from '../features/List/components/ListSnackbar/ListSnackbar'

export function Layout() {
  return (
    <>
      <AppButton />

      <Drawer>
        <Outlet />
      </Drawer>

      <Events />
      <ListSnackbar />
    </>
  )
}
