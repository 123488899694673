import { useParams } from 'react-router-dom'
import { ArrowRight, PaperPlaneTilt, Question } from '@phosphor-icons/react'

import { useAppContext } from '@/main/app/context'
import { useAppSelector } from '@/main/app/hooks/storeHooks'

import { selectListById } from '../../listSlice'

import { ListSubtotal } from '../ListSubtotal/ListSubtotal'

import { Tooltip } from '@/main/components/Tooltip/Tooltip'

export function ListDrawerFooter() {
  const { id } = useParams()

  const list = useAppSelector((state) => selectListById(state, id ?? ''))

  const {
    cartUrl,
    formPaths: [step1, , step3],
  } = useAppContext()

  return list?.id !== undefined ? (
    <>
      <div className="twc-flex twc-w-full twc-justify-between twc-items-start twc-text-sm sm:twc-text-base">
        <div className="twc-inline-flex twc-items-center">
          <strong>Rental Estimate</strong>
          <Tooltip
            align="center"
            title="Estimate info"
            icon={
              <>
                <Question weight="bold" size={20} />
              </>
            }
          >
            <span className="twc-text-xs xs:twc-text-sm">
              Estimate for (1) rental period. This is not a quote. Start a new request to get a
              quote with availability.
            </span>
          </Tooltip>
        </div>
        <strong>
          <ListSubtotal listId={list.id} />
        </strong>
      </div>

      <div className="twc-flex twc-gap-1 xs:twc-gap-2 sm:twc-gap-4 twc-justify-center twc-w-full twc-max-w-full">
        <a
          className="twc-flex-1 xs:twc-basis-auto twc-w-[48%] xs:twc-grow-0 twc-inline-flex twc-items-center twc-justify-center twc-bg-violet-200 dark:twc-bg-[#42456e] dark:twc-text-slate-50  twc-rounded-xl twc-text-slate-900 hover:twc-text-white hover:twc-bg-brand twc-gap-1 twc-p-2 dark:hover:twc-border-brand dark:hover:twc-bg-brand dark:hover:twc-text-white"
          href={`${cartUrl}/#${list.id ? '/' + list.id : ''}`}
        >
          <span className="twc-overflow-hidden twc-text-ellipsis twc-whitespace-nowrap twc-leading-loose twc-text-xm sm:twc-text-lg sm:twc-leading-loose">
            Review {list.name ?? 'Cart'}
          </span>{' '}
          <ArrowRight size={18} />
        </a>

        {/* if last sent, go to form/3 and "Update My Request", else, go to form/1 and "Start New Request" */}
        <a
          className="twc-flex-1 xs:twc-basis-auto twc-w-[48%] xs:twc-grow-0 twc-inline-flex twc-items-center twc-justify-center twc-bg-primary twc-rounded-xl twc-text-indigo-50 hover:twc-text-white hover:twc-bg-brand twc-gap-1 twc-p-2 dark:twc-text-indigo-50 dark:hover:twc-text-white"
          href={`${cartUrl}/#/${list.id ? list.id + '/' : ''}form/${
            list.history?.sent ? step3 : step1
          }`}
        >
          <span className="twc-overflow-hidden twc-text-ellipsis twc-whitespace-nowrap twc-leading-loose twc-text-xm sm:twc-text-lg sm:twc-leading-loose">
            {list.history?.sent ? `Update ${list.name}` : 'Start New Request'}
          </span>{' '}
          <PaperPlaneTilt size={18} weight="bold" />
        </a>
      </div>
    </>
  ) : null
}
