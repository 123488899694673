import { useAppSelector } from '@/main/app/hooks/storeHooks'
import { selectItemsByListId } from '@/main/features/Item/itemSlice'
import { Listbox, Transition } from '@headlessui/react'
import { CaretDown, CheckCircle, Circle, ListPlus } from '@phosphor-icons/react'
import { Fragment, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigateList } from '../../hooks/useNavigateList'
import { List, ListId, selectListById, selectLists } from '../../listSlice'
import { ListCreateDialog } from '../ListDialogs/ListCreateDialog/ListCreateDialog'

export function ListTitle() {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const { id } = useParams()
  const lists = useAppSelector(selectLists)
  const list = useAppSelector((state) => selectListById(state, id ?? ''))
  const items = useAppSelector((state) => selectItemsByListId(state, list?.id))
  const quantity = items.reduce((sum, item) => sum + item.count, 0)

  const otherLists = lists.filter((l) => l.id !== list?.id)

  const onNavigateList = useNavigateList()

  const onChangeList = useCallback(
    (selected?: List | 'open') => {
      if (selected === 'open') {
        setIsCreateDialogOpen(true)
      } else if (selected) {
        onNavigateList(selected.id)
      }
    },
    [onNavigateList]
  )

  const onCloseDialog = useCallback(
    (id?: ListId) => {
      setIsCreateDialogOpen(false)

      if (id) {
        onNavigateList(id)
      }
    },
    [onNavigateList, setIsCreateDialogOpen]
  )

  return (
    <>
      {list ? (
        <Listbox value={list} onChange={onChangeList}>
          <div className="twc-relative twc-h-full twc-mx-2">
            <Listbox.Button className="motion-safe:twc-transition-colors ui-open:twc-bg-sky-100 ui-not-open:twc-bg-transparent twc-relative twc-pl-3 twc-pr-6 twc-py-1 twc-text-lg twc-h-full twc-rounded-md twc-flex twc-items-center twc-mx-auto twc-gap-1.5 twc-group hover:twc-bg-sky-200 ui-open:hover:twc-bg-sky-200 ui-not-open:hover:twc-bg-sky-200 dark:ui-open:twc-bg-sky-800 dark:ui-not-open:twc-bg-transparent dark:hover:twc-bg-sky-700 dark:ui-open:hover:twc-bg-sky-700 dark:ui-not-open:hover:twc-bg-sky-700 twc-border twc-border-transparent">
              <span className="twc-block twc-truncate twc-text-ellipsis twc-font-extrabold">
                {list?.name ?? 'My Project'} ({quantity})
              </span>
              <span className="twc-inline-flex twc-absolute twc-right-1.5 twc-inset-y-0 twc-items-center twc-pointer-events-none twc-text-gray-500  group-hover:twc-text-black dark:group-hover:twc-text-white">
                <CaretDown size={12} weight="fill" />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="twc-transition twc-ease-in twc-duration-100"
              leaveFrom="twc-opacity-100"
              leaveTo="twc-opacity-0"
            >
              <Listbox.Options className="twc-absolute twc-text-sm twc-py-0.5 twc-mt-1 twc-bg-white twc-shadow-md twc-rounded-lg dark:twc-bg-zinc-800 twc-max-h-60 twc-w-full twc-overflow-auto">
                <Listbox.Option
                  key={list.id}
                  value={list}
                  className="motion-safe:twc-transition-colors twc-relative twc-pl-6 twc-pr-2 twc-py-2 ui-active:twc-bg-slate-200 dark:ui-active:twc-bg-slate-700"
                >
                  <span className="twc-block twc-truncate twc-pl-3 twc-font-bold">{list.name}</span>
                  <span className="twc-hidden ui-selected:twc-flex twc-absolute twc-inset-y-0 twc-left-0 twc-items-center twc-pl-3">
                    <CheckCircle size={16} weight="bold" aria-hidden="true" />
                  </span>
                </Listbox.Option>
                {otherLists.map((l) => (
                  <Listbox.Option
                    key={l.id}
                    value={l}
                    className="motion-safe:twc-transition-colors twc-relative twc-pl-6 twc-pr-2 twc-py-2 ui-active:twc-bg-slate-200 dark:ui-active:twc-bg-slate-700"
                  >
                    <span className="twc-block twc-truncate twc-pl-3 ui-selected:twc-font-bold">
                      {l.name}
                    </span>
                    <span className="twc-hidden ui-selected:twc-flex twc-absolute twc-inset-y-0 twc-left-0 twc-items-center twc-pl-3">
                      <CheckCircle size={16} weight="bold" aria-hidden="true" />
                    </span>
                    <span className="twc-hidden ui-not-selected:twc-flex twc-absolute twc-inset-y-0 twc-left-0 twc-items-center twc-pl-3">
                      <Circle size={16} weight="thin" aria-hidden="true" />
                    </span>
                  </Listbox.Option>
                ))}
                <Listbox.Option
                  key="createProject"
                  value="open"
                  className="twc-bg-white dark:twc-bg-zinc-800 twc-sticky twc-bottom-0 motion-safe:twc-transition-colors twc-border-t dark:twc-border-t-zinc-600 twc-py-2 ui-active:twc-bg-primary ui-active:twc-text-white"
                >
                  <span className="twc-inline-flex twc-justify-center twc-w-full twc-gap-1 twc-items-center twc-mx-auto twc-text-center">
                    Create New Project <ListPlus size={16} weight="thin" aria-hidden="true" />
                  </span>
                </Listbox.Option>
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      ) : (
        <h2 className="twc-leading-loose twc-text-center font-medium">My Cart</h2>
      )}

      <ListCreateDialog isOpen={isCreateDialogOpen} onClose={onCloseDialog} />
    </>
  )
}
