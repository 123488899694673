import { Navigate, useParams } from 'react-router-dom'

import { useAppSelector } from '../app/hooks/storeHooks'
import { useList } from '../features/List/hooks/useList'

import { ItemCards } from '../features/Item/components/ItemCard/ItemCards'
import { ListWhen } from '../features/List/components/ListWhen'

export function Project() {
  const { id } = useParams()
  const list = useList(id)
  const isReady = useAppSelector((state) => state.app.isReady)

  return list === undefined ? (
    <Navigate to={`/cart/`} />
  ) : (
    <ListWhen isReady={isReady ?? false} isEmpty={!list?.itemIds || list.itemIds.length === 0}>
      <ItemCards ids={list.itemIds} />
    </ListWhen>
  )
}
